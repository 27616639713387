<template>
  <div class="main">
    <div id="loading">
      <div id="loading-center">
        <div id="loading-center-absolute">
          <div class="object" id="object_one"></div>
          <div class="object" id="object_two"></div>
          <div class="object" id="object_three"></div>
          <div class="object" id="object_four"></div>
          <div class="object" id="object_five"></div>
          <div class="object" id="object_six"></div>
          <div class="object" id="object_seven"></div>
          <div class="object" id="object_eight"></div>
          <div class="object" id="object_big"></div>
        </div>
      </div>
    </div>
    <div class="txt">
      <div class="p">{{ $t('正在验证您的身份，请稍等') }}</div>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { switchAuth } from "@/api/user";
import { successCallback } from "@/utils/login.js";
export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      key: route.query.key || "",
      redirect: decodeURIComponent(route.query.redirect || "/"),
    });

    onMounted(() => {
      fnLogin();
    });

    const fnLogin = () => {
      switchAuth({ key: state.key }).then((res) => {
        if (res.ret == 0) {
          successCallback(res, state);
          store.dispatch("setPlatform", "web");
        }
      });
    };

    return {};
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/style/login.less";
</style>